import React from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";

const Footer = () => {

    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"body1"} color={"text.primary"}>© {(new Date().getFullYear())} Tüm hakları saklıdır. Bu sitede yer alan herhangi bir verinin kaynak belirtmeden veya izin almadan kullanılması halinde telif haklarını düzenleyen 5846 sayılı yasa çerçevesinde işlem yapılacaktır.</Typography>
            </Div>
        </Div>
    );
};

export default Footer;
