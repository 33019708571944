import React, {useState} from 'react';
import moment from "moment"
import TextField from '@mui/material/TextField';
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider, trTR} from "@mui/x-date-pickers";
import RaceDays from "./components/RaceDays"
import Div from "@jumbo/shared/Div";
import {Button} from "@mui/material";
import Grid from "@mui/material/Grid";
import tr from "moment/locale/tr"

const Machine = () =>
{
    const [date, setDate] = useState(new moment());
    const [raceDate, setRaceDate] = useState(new moment());

    const onDateChange = (newValue) =>
    {
        setDate(newValue);
    };

    const onDateAccept = (newValue) =>
    {
        setRaceDate(newValue);
    };

    return (
        <Div>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterMoment}
                                          adapterLocale={'tr'}
                                          localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <MobileDatePicker
                            label="Yarış Tarihi"
                            inputFormat='LL'
                            value={date}
                            showToolbar={false}
                            onChange={onDateChange}
                            onAccept={onDateAccept}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <Button size={"small"} variant="contained" href="/reward" target="_blank" fullWidth>
                        İKRAMİYE HESAPLAMA
                    </Button>
                </Grid>
            </Grid>
            <RaceDays raceDate={raceDate}/>
        </Div>
    );
};

export default Machine;