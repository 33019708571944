import React, {useEffect, useState} from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useQuery} from "react-query";
import TMService from "../../services/TMService";
import Div from "@jumbo/shared/Div";
import {CircularProgress, ListItem, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import moment from "moment";
import {alpha, styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
    PagingState,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid as DataGrid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {API_URL} from "../../utils/constants/apiUrl";

let columns = [{
    title: 'Tarih',
    name: 'date',
    getCellValue: row => moment(row.raceHorseKey.race.raceDay.date, "YYYY-MM-DD").format('DD.MM.YYYY').toString()
}, {
    title: 'Şehir',
    name: 'place',
    getCellValue: row => row.raceHorseKey.race.raceDay.raceCourse.place
}, {
    title: 'Mesafe',
    name: 'distance',
    getCellValue: row => row.raceHorseKey.race.distance
}, {
    title: 'Pist',
    name: 'track',
    getCellValue: row =>
    {
        let track = row.raceHorseKey.race.track.name;

        if (row.raceHorseKey.race.track.id === 1 && row.raceHorseKey.race.raceDay.turfWeight)
        {
            track += ' (';
            track += row.raceHorseKey.race.raceDay.turfWeight.toString();
            track += ')';
        } else if (row.raceHorseKey.race.raceDay.dirtState)
        {
            track += ' (';
            track += row.raceHorseKey.race.raceDay.dirtState.name;
            track += ')';
        }

        return track
    }
}, {
    title: 'S',
    name: 'result'
}, {
    title: 'Takı',
    name: 'accessory'
}, {
    title: 'Derece',
    dataIndex: 'degree',
    key: 'degree',
    getCellValue: row => row.degree ? moment(row.degree * 1000).format('mm.ss.SS') : ''
}, {
    title: 'Kilo',
    name: 'weight',
}, {
    title: 'Jokey',
    name: 'jockey',
    getCellValue: row => row.jockey.name
}, {
    title: 'Ganyan',
    name: 'odds'
}, {
    title: 'Grup',
    name: 'group',
    getCellValue: row => row.raceHorseKey.race.group.name
}, {
    title: 'Koşu Tipi',
    name: 'type',
    getCellValue: row => row.raceHorseKey.race.type.name
}
];

const classes = {
    tableStriped: `simple-detail-tableStriped`,
};
const StyledTable = styled(Table.Table)(({theme}) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
    },
}));

const TableComponent = props => (
    <StyledTable
        {...props}
        className={classes.tableStriped}
    />
);

const HorseDetail = ({id}) =>
{
    const [races, setRaces] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [pageSizes] = useState([5, 10, 20]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastQuery, setLastQuery] = useState("");

    const {
        data: horse,
        isLoading,
        isError,
        error
    } = useQuery("horse-detail", () => TMService.getDetail('horse', id));

    const {
        data: siblings
    } = useQuery("horse-sibling-detail", () => TMService.getSiblings(horse.dam.id, horse.sire.id),
        {enabled: horse?.id > 0});

    const {
        data: resultStats
    } = useQuery("horse-stat", () => TMService.getStat(id), {enabled: horse?.id > 0});

    const raceQueryString = () => (
        `${API_URL}/race/horse/${id}?page=${currentPage}&size=${pageSize}`
    );


    const loadData = () =>
    {
        const queryString = raceQueryString();

        if (queryString !== lastQuery && !loading)
        {
            setLoading(true);
            fetch(queryString)
                .then(response => response.json())
                .then(data =>
                {
                    setRaces(data.content);
                    setTotalCount(data.totalElements);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
            setLastQuery(queryString);
        }
    };

    useEffect(() => loadData());


    if (isLoading)
    {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(3),
                    m: 'auto',
                }}
            >
                <CircularProgress/>
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>Yükleniyor...</Typography>
            </Div>
        )
    }
    if (isError)
    {
        return error.message;
    }

    let siblingListItems = [];

    if (siblings)
    {
        for (let i = 0; i < siblings.length; i++)
        {
            if (siblings[i].id !== horse.id)
            {
                siblingListItems.push(
                    <ListItem key={siblings[i].id}>
                        <ListItemText primary={
                            <Link underline="hover" href={'/details/horse/' + siblings[i].id}
                                  target="_blank">{siblings[i].name}</Link>}/>
                    </ListItem>)
            }
        }
    }

    if (horse)
    {
        return (
            <JumboCardQuick title={horse.name} noWrapper
                            headerSx={{
                                borderBottom: 1, borderColor: 'divider'
                            }}>
                <Grid container spacing={3.75}>
                    <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                        <JumboCardQuick title={'Temel Bilgiler'}
                                        headerSx={{
                                            borderBottom: 1, borderColor: 'divider'
                                        }}>
                            <List dense={true}>
                                {horse.dam &&
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link underline="hover" href={'/details/dam/' + horse.dam.id}
                                                      target="_blank">{horse.dam.name}</Link>}
                                            secondary='Anne'
                                        />
                                    </ListItem>
                                }
                                {horse.sire &&
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link underline="hover" href={'/details/sire/' + horse.sire.id}
                                                      target="_blank">{horse.sire.name}</Link>}
                                            secondary='Baba'
                                        />
                                    </ListItem>
                                }
                                {horse.dam && horse.dam.sire &&
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link underline="hover" href={'/details/sire/' + horse.dam.sire.id}
                                                      target="_blank">{horse.dam.sire.name}</Link>}
                                            secondary='Baba (Anne)'
                                        />
                                    </ListItem>
                                }
                                {horse.trainer &&
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link underline="hover" href={'/details/trainer/' + horse.trainer.id}
                                                      target="_blank">{horse.trainer.name}</Link>}
                                            secondary='Antrenör'
                                        />
                                    </ListItem>
                                }
                                {horse.owner &&
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Link underline="hover" href={'/details/owner/' + horse.owner.id}
                                                      target="_blank">{horse.owner.name}</Link>}
                                            secondary='Sahip'
                                        />
                                    </ListItem>
                                }
                            </List>
                        </JumboCardQuick>
                    </Grid>
                    <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                        <JumboCardQuick title={'Kardeşler'} noWrapper
                                        headerSx={{
                                            borderBottom: 1, borderColor: 'divider'
                                        }}>
                            <List dense={true}>
                                {siblingListItems}
                            </List>
                        </JumboCardQuick>
                    </Grid>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                        <JumboCardQuick title={'Performans'} noWrapper
                                        headerSx={{
                                            borderBottom: 1, borderColor: 'divider'
                                        }}>

                            {resultStats &&
                                <ResponsiveContainer width="100%" height={250}>
                                    <BarChart
                                        componentName={'ResultStatGraph'}
                                        data={[
                                            {
                                                name: '1',
                                                dirt: resultStats.firstPlaceOnDirtCount,
                                                turf: resultStats.firstPlaceOnTurfCount,
                                                synth: resultStats.firstPlaceOnSynthCount
                                            },
                                            {
                                                name: '2',
                                                dirt: resultStats.secondPlaceOnDirtCount,
                                                turf: resultStats.secondPlaceOnTurfCount,
                                                synth: resultStats.secondPlaceOnSynthCount
                                            },
                                            {
                                                name: '3',
                                                dirt: resultStats.thirdPlaceOnDirtCount,
                                                turf: resultStats.thirdPlaceOnTurfCount,
                                                synth: resultStats.thirdPlaceOnSynthCount
                                            },
                                            {
                                                name: '4',
                                                dirt: resultStats.fourthPlaceOnDirtCount,
                                                turf: resultStats.fourthPlaceOnTurfCount,
                                                synth: resultStats.fourthPlaceOnSynthCount
                                            },
                                            {
                                                name: '4+',
                                                dirt: resultStats.totalRaceOnDirtNonTable,
                                                turf: resultStats.totalRaceOnTurfNonTable,
                                                synth: resultStats.totalRaceOnSynthNonTable
                                            },
                                        ]}
                                    >
                                        <XAxis dataKey="name" stroke={'#788195'}/>
                                        <YAxis stroke={'#788195'}/>
                                        <CartesianGrid strokeDasharray="2 2"/>
                                        <Tooltip wrapperStyle={{outline: 'none'}}/>
                                        <Legend/>
                                        <Bar name={'Çim'} dataKey="turf" fill={'#009900'}/>
                                        <Bar name={'Kum'} dataKey="dirt" stackId="a" fill={'#996633'}/>
                                        <Bar name={'Sentetik'} dataKey="synth" stackId="a" fill={'#D39B1E'}/>
                                    </BarChart>
                                </ResponsiveContainer>}
                        </JumboCardQuick>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <JumboCardQuick title={'Yarışlar'} noWrapper
                                        headerSx={{
                                            borderBottom: 1, borderColor: 'divider'
                                        }}>
                            <Paper style={{position: 'relative'}}>
                                <DataGrid
                                    rows={races}
                                    columns={columns}
                                >
                                    <PagingState
                                        currentPage={currentPage}
                                        onCurrentPageChange={setCurrentPage}
                                        pageSize={pageSize}
                                        onPageSizeChange={setPageSize}
                                    />
                                    <CustomPaging
                                        totalCount={totalCount}
                                    />
                                    <Table tableComponent={TableComponent}/>
                                    <TableHeaderRow/>
                                    <PagingPanel
                                        pageSizes={pageSizes}
                                        messages={{rowsPerPage: 'Satır sayısı'}}/>
                                </DataGrid>
                                {loading && <CircularProgress/>}
                            </Paper>
                        </JumboCardQuick>
                    </Grid>
                </Grid>
            </JumboCardQuick>)
    } else
    {
        return (
            <Div></Div>
        )
    }

};

export default HorseDetail;