import React, {useEffect, useState} from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import {
    PagingState,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid as DataGrid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {alpha, styled} from "@mui/material/styles";
import {useQuery} from "react-query";
import TMService from "../../services/TMService";
import {API_URL} from "../../utils/constants/apiUrl";
import {CircularProgress, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

const classes = {
    tableStriped: `simple-detail-tableStriped`,
};
const StyledTable = styled(Table.Table)(({theme}) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
    },
}));

const TableComponent = props => (
    <StyledTable
        {...props}
        className={classes.tableStriped}
    />
);

let columns = [{
    title: 'At İsmi',
    name: 'name',
    getCellValue: row => (<Link underline="hover" href={'/details/horse/' + row.id} target="_blank">
        {row.name} </Link>)
}, {
    title: 'Irk',
    name: 'breed',
    getCellValue: row => (row.breed.name)
}, {
    title: 'Baba',
    name: 'sire',
    getCellValue: row => (<Link underline="hover" href={'/details/sire/' + row.sire.id} target="_blank">
        {row.sire.name} </Link>)
}, {
    title: 'Anne',
    name: 'dam',
    getCellValue: row => (<Link underline="hover" href={'/details/dam/' + row.dam.id} target="_blank">
        {row.dam.name} </Link>)
}, {
    title: 'Sahip',
    name: 'owner',
    getCellValue: row => (<Link underline="hover" href={'/details/owner/' + row.owner.id} target="_blank">
        {row.owner.name} </Link>)
}, {
    title: 'Antrenör',
    name: 'trainer',
    getCellValue: row => row.trainer && (
        <Link underline="hover" href={'/details/trainer/' + row.trainer.id} target="_blank">
            {row.trainer.name} </Link>)
}
];

const SimpleDetail = ({type, id}) =>
{
    const [horses, setHorses] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [pageSizes] = useState([5, 10, 20]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastQuery, setLastQuery] = useState("");

    const getQueryString = () => (
        `${API_URL}/horse/${type}/${id}?page=${currentPage}&size=${pageSize}`
    );

    const {
        data: entity,
        isLoading,
        isError,
        error
    } = useQuery(["get-detail-query"], () => TMService.getDetail(type, id));

    const loadData = () =>
    {
        const queryString = getQueryString();

        if (queryString !== lastQuery && !loading)
        {
            setLoading(true);
            fetch(queryString)
                .then(response => response.json())
                .then(data =>
                {
                    setHorses(data.content);
                    setTotalCount(data.totalElements);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
            setLastQuery(queryString);
        }
    };

    useEffect(() => loadData());

    if (isLoading)
    {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(3),
                    m: 'auto',
                }}
            >
                <CircularProgress/>
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>Yükleniyor...</Typography>
            </Div>
        )
    }
    if (isError)
    {
        return error.message;
    }

    return (
        <JumboCardQuick title={entity.name} noWrapper
                        headerSx={{
                            borderBottom: 1, borderColor: 'divider'
                        }}>
            <Paper style={{position: 'relative'}}>
                <DataGrid
                    rows={horses}
                    columns={columns}
                >
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                    />
                    <CustomPaging
                        totalCount={totalCount}
                    />
                    <Table tableComponent={TableComponent}/>
                    <TableHeaderRow/>
                    <PagingPanel
                        pageSizes={pageSizes}
                        messages={{rowsPerPage: 'Satır sayısı'}}/>
                </DataGrid>
                {loading && <CircularProgress/>}
            </Paper>
        </JumboCardQuick>
    )
};

export default SimpleDetail;