import SoloPage from "../../layouts/solo-page/SoloPage";
import TMDefault from "../../layouts/tm-default/TMDefault";

const LAYOUT_NAMES = {
    SOLO_PAGE: "solo-page",
    TM_DEFAULT: "tm-default"
};

const LAYOUTS = [
    {
        name: LAYOUT_NAMES.SOLO_PAGE,
        label: "Solo Page",
        component: SoloPage
    },
    {
        name: LAYOUT_NAMES.TM_DEFAULT,
        label: "TM Default",
        component: TMDefault
    }
];

export {LAYOUTS, LAYOUT_NAMES};