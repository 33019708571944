import {mainTheme} from "../themes/main/theme1";
import {headerTheme} from "../themes/header/theme4";
import {footerTheme} from "../themes/footer/theme1";
import LAYOUT_NAMES from "../layouts/layouts";
import {createJumboTheme} from "@jumbo/utils";

const config = {

    defaultLayout: LAYOUT_NAMES.TM_DEFAULT,

    theme: createJumboTheme(mainTheme, headerTheme, {}, footerTheme),

};

export {config};
