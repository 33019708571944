import React from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {Chip, OutlinedInput} from "@mui/material";
import Div from "@jumbo/shared/Div";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const MultiSelect = ({label, options, selected, setSelected}) =>
{

    const handleChange = (event) =>
    {
        const value = event.target.value;
        if (value[value.length - 1] === "all")
        {
            setSelected(selected.length === options.length ? [] : options);
            return;
        }
        setSelected(value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                input={<OutlinedInput label={label}/>}
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => (
                    <Div sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map((value) => (
                            <Chip key={value} label={value}/>
                        ))}
                    </Div>
                )}
                MenuProps={MenuProps}
            >
                <MenuItem value="all" sx=
                    {selected.length > 0 && selected.length === options.length
                        ? {
                            bgcolor: "rgba(22, 30, 84, 0.08)",
                            "&:hover": {
                                bgcolor: "rgba(22, 30, 84, 0.12)"
                            }
                        }
                        : {}}>
                    <Checkbox
                        checked={selected.length > 0 && selected.length === options.length}
                        indeterminate={selected.length > 0 && selected.length < options.length
                        }
                    />
                    <ListItemText
                        primary="Hepsini Seç"
                    />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={selected.indexOf(option) > -1}/>
                        <ListItemText primary={option}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultiSelect;