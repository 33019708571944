import React, {useState} from 'react';
import {API_URL} from "../../utils/constants/apiUrl";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Backdrop, CircularProgress, Container, InputAdornment, OutlinedInput} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";

const Reward = () =>
{
    const [unit] = useState(0.5);
    const [distributionRatio] = useState(50);
    const [totalReward] = useState(10000000);
    const [agf1, setAgf1] = useState("");
    const [agf2, setAgf2] = useState("");
    const [agf3, setAgf3] = useState("");
    const [agf4, setAgf4] = useState("");
    const [agf5, setAgf5] = useState("");
    const [agf6, setAgf6] = useState("");
    const [reward, setReward] = useState("");
    const [loading, setLoading] = useState(false);

    const onCalculate = () =>
    {
        setLoading(true);
        const url = API_URL + "/calculate/reward/";
        const myRequest = new Request(url,
            {
                method: 'POST',
                headers: new Headers({'content-type': 'application/json'}),
                body: JSON.stringify({
                    totalReward,
                    unit,
                    distributionRatio,
                    agf1,
                    agf2,
                    agf3,
                    agf4,
                    agf5,
                    agf6,
                })
            });
        fetch(myRequest)
            .then(res =>
            {
                if (res.status === 200)
                {
                    return res.text();
                } else
                {
                    throw new Error('Hesaplama hatası');
                }
            })
            .then(res =>
            {
                setReward(parseInt(res));
                setLoading(false);
            })
            .catch(err =>
            {
                setLoading(false);
                setReward('');
                alert(err);
            });
    };


    return (
        <Container maxWidth="sm">
            <JumboCardQuick title={'Tahmini İkramiye Hesaplama'} noWrapper
                            headerSx={{
                                borderBottom: 1, borderColor: 'divider'
                            }}>
                <Grid
                    container
                    padding={'30px'}
                    spacing={2}
                    alignItems={'center'}
                    direction={'row'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="agf-1"
                            label="AGF 1"
                            value={agf1}
                            onChange={(event) => setAgf1(event.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="agf-2"
                            label="AGF 2"
                            value={agf2}
                            onChange={(event) => setAgf2(event.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="agf-3"
                            label="AGF 3"
                            value={agf3}
                            onChange={(event) => setAgf3(event.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="agf-4"
                            label="AGF 4"
                            value={agf4}
                            onChange={(event) => setAgf4(event.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="agf-5"
                            label="AGF 5"
                            value={agf5}
                            onChange={(event) => setAgf5(event.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="agf-6"
                            label="AGF 6"
                            value={agf6}
                            onChange={(event) => setAgf6(event.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Button
                            onClick={onCalculate}
                            variant="contained"
                            fullWidth
                        >
                            HESAPLA
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormControl             fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">İkramiye</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                value={reward}
                                startAdornment={<InputAdornment position="start">₺</InputAdornment>}
                                label="Reward"
                                readOnly
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </JumboCardQuick>
        </Container>
    );
};

export default Reward;