import {
    SIDEBAR_ANCHOR_POSITIONS,
    SIDEBAR_SCROLL_TYPES,
    SIDEBAR_STYLES,
    SIDEBAR_VARIANTS,
    SIDEBAR_VIEWS
} from "@jumbo/utils/constants/layout";
import SoloPage from "./solo-page/SoloPage";
import TMDefault from "./tm-default/TMDefault";

const LAYOUT_NAMES = {
    VERTICAL_DEFAULT: "vertical-default",
    SOLO_PAGE: "solo-page",
    TM_DEFAULT: "tm-default",
};

const LAYOUTS = [
    {
        name: LAYOUT_NAMES.SOLO_PAGE,
        label: "Solo Page",
        component: SoloPage,
        layoutOptions: {
            sidebar: {
                hide: true,
            },
            header: {
                hide: true,
            },
            footer: {
                hide: true,
            }
        }
    },
    {
        name: LAYOUT_NAMES.TM_DEFAULT,
        label: "TM Default",
        component: TMDefault,
        layoutOptions: {
            sidebar: {
                hide: true,
            },
            header: {
                hide: false,
                fixed: true,
            },
            footer: {
                hide: false,
            }
        }
    }
];

export default LAYOUT_NAMES;
export {LAYOUTS};