import React, {useState} from "react";
import Div from "@jumbo/shared/Div";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Race from './Race';

const RaceDay = ({raceDay}) =>
{
    const [value, setValue] = useState(0);

    raceDay.races.sort(function (a, b)
    {
        return a.raceNo - b.raceNo
    });
    let races = [];
    for (let i = 0; i < raceDay.races.length; i++)
    {
        let race = raceDay.races[i];
        races.push(
            <Tab key={race.id} label={'Koşu ' + race.raceNo.toString()}/>
        );
    }

    return (
        <Paper elevation={3} sx={{marginTop: '20px'}}>
            <Div sx={{bgcolor: 'background.paper'}}>
                <Tabs
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {races}
                </Tabs>
            </Div>
            <Race
                date={raceDay.date}
                raceCourse={raceDay.raceCourse}
                dirtState={raceDay.dirtState}
                race={raceDay.races[value]}/>
        </Paper>

    );
}

export default RaceDay;