import React, {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import {SnackbarProvider} from "notistack";
import {config} from "./config/main";
import Div from "@jumbo/shared/Div";
import {CircularProgress} from "@mui/material";
import {createBrowserHistory} from "history";
import GoogleAds from "./utils/GoogleAds";
import Grid from "@mui/material/Grid";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    const history = createBrowserHistory();

    return (
        <QueryClientProvider client={queryClient}>
                <BrowserRouter history={history}>
                        <JumboApp activeLayout={config.defaultLayout}>
                            <JumboTheme init={config.theme}>
                                    <JumboDialogProvider>
                                        <JumboDialog/>
                                        <SnackbarProvider
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            maxSnack={3}>
                                            <AppLayout>
                                                <Suspense
                                                    fallback={
                                                        <Div
                                                            sx={{
                                                                display: 'flex',
                                                                minWidth: 0,
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <CircularProgress sx={{m: '-40px auto 0'}}/>
                                                        </Div>
                                                    }
                                                >
                                                    <Grid container justifyContent="center" spacing={2}>
                                                        <GoogleAds slot="2639476488"/>
                                                    </Grid>
                                                    <AppRoutes/>
                                                    <Grid container justifyContent="center" spacing={2}>
                                                        <GoogleAds slot="1116665340"/>
                                                    </Grid>
                                                </Suspense>
                                            </AppLayout>
                                        </SnackbarProvider>
                                    </JumboDialogProvider>
                            </JumboTheme>
                        </JumboApp>
                </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
