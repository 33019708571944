import React, { useEffect } from 'react';
import Div from "@jumbo/shared/Div";

const GoogleAds = ({slot}) => {

    useEffect(() =>
    {
        if(window) (window.adsbygoogle = window.adsbygoogle || []).push({});
    });

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {/*<Div  sx={{display: 'block', width: "100vh", margin: '24px', bgcolor: '#cfe8fc', height: '10vh'}} ></Div>*/}
            <ins className="adsbygoogle"
                 style={{display: 'block', width: "100%", margin: '24px'}}
                 data-ad-client="ca-pub-9129100286464041"
                 data-ad-slot={slot}
                 data-ad-format="auto"
                 data-full-width-responsive="true">
            </ins>
        </Div>
        );
};

export default GoogleAds;