import React from 'react';
import {useParams} from "react-router-dom";
import Div from "@jumbo/shared/Div";
import HorseDetail from "./HorseDetail";
import SimpleDetail from "./SimpleDetail";


const Details = () =>
{
    const {type, id} = useParams();

    if (type === 'horse')
    {
        return (
            <Div>
                <HorseDetail id={id}/>
            </Div>
        )
    }
    else
    {
        return (
            <Div>
                <SimpleDetail id={id} type={type}/>
            </Div>
        )
    }
};

export default Details;