import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    FormGroup,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import moment from "moment";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider, trTR} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MultiSelect from "./MultiSelect"
import Grid from "@mui/material/Grid";
import tr from "moment/locale/tr"
import Button from "@mui/material/Button";

const raceTrackList = [
    "Çim",
    "Kum",
    "Sentetik"
];

const raceCourseList = [
    "Adana",
    "İzmir",
    "İstanbul",
    "Bursa",
    "Ankara",
    "Şanlıurfa",
    "Elazığ",
    "Diyarbakır",
    "Kocaeli",
    "Antalya",
];

const dirtStateList = [
    "Normal",
    "Nemli",
    "Islak",
    "Sulu",
    "Ağır"
];

const Estimator = ({date, raceCourse, dirtState, loading, race, onCalculate}) =>
{

    useEffect(() =>
    {
        let initialDirtState = [1];
        if (dirtState && dirtState.id > 1)
        {
            for (let d = dirtState.id; d > 1; d--)
            {
                initialDirtState.push(d);
            }
        }

        setMinDate(moment(date, "YYYY-MM-DD").subtract(4, 'months'));
        setMaxDate(moment(date, "YYYY-MM-DD").subtract(1, 'days'));
        setMinDistance(race.distance - 400);
        setMaxDistance(race.distance + 400);
        setJockeyWeight(false);
        setEliminateWorseRaces(true);
        setCalculateOriginRating(false);
        setDirtStates(Array.from(initialDirtState, x => dirtStateList[x - 1]));
        setRaceTracks([raceTrackList[race.track.id - 1]]);
        setRaceCourses([raceCourseList[raceCourse.id - 1]]);
    }, [race, date, raceCourse, dirtState])

    const [minDate, setMinDate] = useState(moment(date, "YYYY-MM-DD").subtract(4, 'months'));
    const [maxDate, setMaxDate] = useState(moment(date, "YYYY-MM-DD").subtract(1, 'days'));
    const [minDistance, setMinDistance] = useState(race.distance - 400);
    const [maxDistance, setMaxDistance] = useState(race.distance + 400);
    const [jockeyWeight, setJockeyWeight] = useState(false);
    const [eliminateWorseRaces, setEliminateWorseRaces] = useState(true);
    const [calculateOriginRating, setCalculateOriginRating] = useState(false);
    const [dirtStates, setDirtStates] = useState([]);
    const [raceTracks, setRaceTracks] = useState([raceTrackList[race.track.id - 1]]);
    const [raceCourses, setRaceCourses] = useState([raceCourseList[raceCourse.id - 1]]);


    return (

        <Card>
            <CardHeader title={"Hesaplama Parametreleri"}/>
            <Divider/>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    alignItems={'stretch'}
                    direction={'column'}
                    justifyContent={'flex-start'}
                >
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment}
                                              adapterLocale={'tr'}
                                              localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <MobileDatePicker
                                label="Başlangıç Tarihi"
                                inputFormat='LL'
                                showToolbar={false}
                                value={minDate}
                                onChange={setMinDate}
                                renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment}
                                              adapterLocale={'tr'}
                                              localeText={trTR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <MobileDatePicker
                                label="Bitiş Tarihi"
                                inputFormat='LL'
                                showToolbar={false}
                                value={maxDate}
                                onChange={setMaxDate}
                                renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="min-distance"
                            label="Minimum Mesafe"
                            type="number"
                            value={minDistance}
                            onChange={(event) => setMinDistance(parseInt(event.target.value))}
                            inputProps={{
                                min: 800,
                                max: 3200,
                                step: 100
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="max-distance"
                            label="Maksimum Mesafe"
                            type="number"
                            value={maxDistance}
                            onChange={(event) => setMaxDistance(parseInt(event.target.value))}
                            inputProps={{
                                min: 800,
                                max: 3200,
                                step: 100
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="jockey-weight"
                                    checked={jockeyWeight}
                                    onChange={(event, checked) => setJockeyWeight(checked)}
                                />
                            }
                            label="Jokey Ağırlık"
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="eliminate-races"
                                        checked={eliminateWorseRaces}
                                        onChange={(event, checked) => setEliminateWorseRaces(checked)}
                                    />
                                }
                                label="Kötü Yarışları At"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="calculate-origin"
                                        checked={calculateOriginRating}
                                        onChange={(event, checked) => setCalculateOriginRating(checked)}
                                    />
                                }
                                label="Orijin Puanı Hesapla"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item>
                        <MultiSelect label={"Hipodromlar"} options={raceCourseList} selected={raceCourses}
                                     setSelected={setRaceCourses}/>
                    </Grid>

                    <Grid item>
                        <MultiSelect label={"Pistler"} options={raceTrackList} selected={raceTracks}
                                     setSelected={setRaceTracks}/>
                    </Grid>

                    <Grid item>
                        <MultiSelect label={"Kum Durumları"} options={dirtStateList} selected={dirtStates}
                                     setSelected={setDirtStates}/>
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={() =>
                            {
                                let parameters = {};
                                parameters["minDate"] = minDate.format('YYYY-MM-DD');
                                parameters["maxDate"] = maxDate.format('YYYY-MM-DD');
                                parameters["minDistance"] = minDistance;
                                parameters["maxDistance"] = maxDistance;
                                parameters["jockeyWeight"] = jockeyWeight;
                                parameters["eliminateWorseRaces"] = eliminateWorseRaces;
                                parameters["calculateOriginRating"] = calculateOriginRating;
                                parameters["dirtStates"] = [];
                                dirtStates.forEach(e => parameters["dirtStates"].push(dirtStateList.indexOf(e) + 1))
                                parameters["raceCourses"] = [];
                                raceCourses.forEach(e => parameters["raceCourses"].push(raceCourseList.indexOf(e) + 1))
                                parameters["raceTracks"] = [];
                                raceTracks.forEach(e => parameters["raceTracks"].push(raceTrackList.indexOf(e) + 1))

                                onCalculate(parameters);

                            }}
                            variant="contained"
                            fullWidth
                        >
                            HESAPLA
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
}

export default Estimator;