import React from "react";
import Machine from "../pages/machine";
import Details from "../pages/details";
import Reward from "../pages/reward";
import Error404 from "../pages/404"
import Page from "@jumbo/shared/Page";

const routes = [
    {
        path: "/",
        element: <Page component={Machine} />
    },
    {
        path: "/details/:type/:id",
        element: <Page component={Details} />
    },
    {
        path: "/reward",
        element: <Page component={Reward} />
    },
    {
        path: "*",
        element: <Page component={Error404} />
    },
];

export default routes;