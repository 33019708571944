import React, {useState} from "react";
import TMService from "../../../services/TMService"
import {useQuery} from "react-query";
import Div from "@jumbo/shared/Div";
import {CircularProgress, Typography, Tab, Card, AppBar, Tabs, Box} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import RaceDay from "./RaceDay"

const TabPanel = (props) =>
{
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 0}}>
                    {children}
                </Div>
            )}
        </div>
    );
};

const a11yProps = (index) =>
{
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const RaceDays = ({raceDate}) =>
{
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const {
        data,
        isLoading,
        isError,
        error
    } = useQuery(["date", raceDate], () => TMService.getRaceDay(raceDate));

    if (isLoading)
    {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(3),
                    m: 'auto',
                }}
            >
                <CircularProgress/>
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>Yükleniyor...</Typography>
            </Div>
        )
    }
    if (isError)
    {
        return error.message;
    }

    let raceDayTabs = [];
    let raceDays = [];
    for (let i = 0; i < data.length; i++)
    {
        let raceDay = data[i];

        raceDayTabs.push(
            <Tab key={raceDay.id} className="tab" label={raceDay.raceCourse.place} {...a11yProps({i})}/>
        );
        raceDays.push(
            <Box key={raceDay.id.toString() + '-container'}>
                {value === i &&
                    <TabPanel value={value} index={i} dir={theme.direction} >
                        <Div sx={{paddingTop: 3, paddingLeft: 1}}>
                            {raceDay.turfWeight && 'Çim: ' + raceDay.turfWeight.toString() + "\t"}
                            {raceDay.dirtState && 'Kum: ' + raceDay.dirtState.name.toString() + "\t"}
                            {raceDay.weather && 'Hava: ' + raceDay.weather}
                        </Div>
                        <RaceDay raceDay={raceDay}/>
                    </TabPanel>}
            </Box>
        );
    }

    return (

        <Card style={{marginTop: '10px'}} >
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {raceDayTabs}
                </Tabs>
            </AppBar>
            {raceDays}
        </Card>
    );

};

export default RaceDays;