import axios from "./config";
import {API_URL} from "../utils/constants/apiUrl";

const TMService = {};

TMService.getRaceDay = async (date) =>
{
    const {data} = await axios.get(`${API_URL}/raceday/date/${date.format('DDMMYYYY')}`);
    return data;
};

TMService.getDetail = async (type, id) =>
{
    const {data} = await axios.get(`${API_URL}/${type}/${id}`);
    return data;
};

TMService.getSiblings = async (damId, sireId) =>
{
    const {data} = await axios.get(`${API_URL}/horse/dam/${damId}/sire/${sireId}`);
    return data;
};

TMService.getStat = async (horseId) =>
{
    const {data} = await axios.get(`${API_URL}/horse/${horseId}/stat`);
    return data;
};


TMService.calculateReward = async (parameters) =>
{
    const {data} = await axios.post(`${API_URL}/calculate/reward`, parameters);
    return data;
};

export default TMService;