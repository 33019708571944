import React from 'react';
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import Div from "@jumbo/shared/Div";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";

const Logo = ({mini, mode, sx}) => {
    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            <Link href={'/'}>
                {
                    !mini ?
                        <img src={ mode === "light" ? `${ASSET_IMAGES}/logo4.png` : `${ASSET_IMAGES}/logo1.png`} alt="Tahmin Makinesi" />
                        :
                        <img src={mode === "light" ? `${ASSET_IMAGES}/logo4.png` : `${ASSET_IMAGES}/logo1.png`} alt="Tahmin Makinesi" />
                }
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

const Header = () => {
    const {headerTheme} = useJumboHeaderTheme();

    return (
        <React.Fragment>
            {
                <Logo sx={{mr: 3}} mode={headerTheme.type ?? "light"}/>
            }
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                <IconButton
                    sx={{
                        borderRadius: 1,
                        color: 'common.white',
                        background: 'linear-gradient(135deg, #91a9f1, #575fd8)',

                        '&:hover': {
                            background: 'linear-gradient(135deg, #575fd8, #91a9f1)',
                        }
                    }}
                    aria-label="facebook"
                    target="_blank"
                    href="https://www.facebook.com/tahminmakinesi"
                >
                    <FacebookIcon/>
                </IconButton>
                <IconButton
                    sx={{
                        borderRadius: 1,
                        color: 'common.white',
                        background: 'linear-gradient(135deg, #36bae0, #5a80e8)',

                        '&:hover': {
                            background: 'linear-gradient(135deg, #5a80e8, #36bae0)',
                        }
                    }}
                    aria-label="Twitter"
                    target="_blank"
                    href="https://www.twitter.com/tahminmakinesi"
                >
                    <TwitterIcon/>
                </IconButton>
            </Stack>
        </React.Fragment>
    );
};

export default Header;
