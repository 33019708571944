import React from "react";
import Link from "@mui/material/Link";
import moment from 'moment';


const ProgramColumns = [
    {
        title: 'No',
        name: 'no',
    }, {
        title: 'At ismi',
        name: 'raceHorseKey.horse',
        getCellValue: row => (
            <span>
                <Link underline="hover" href={'/details/horse/' + row.raceHorseKey.horse.id}
                      target="_blank">{row.raceHorseKey.horse.name} </Link>
                <sup> {row.accessory}</sup>
            </span>
        )
    }, {
        title: 'Yaş',
        name: 'age'
    }, {
        title: 'Baba',
        name: 'raceHorseKey.sire',
        getCellValue: row => (
            <Link underline="hover" href={'/details/sire/' + row.raceHorseKey.horse.sire.id} target="_blank">
                {row.raceHorseKey.horse.sire.name} </Link>)
    }, {
        title: 'Anne',
        name: 'raceHorseKey.dam',
        getCellValue: row => (
            <span>
                <Link underline="hover" href={'/details/dam/' + row.raceHorseKey.horse.dam.id}
                      target="_blank">{row.raceHorseKey.horse.dam.name} </Link>
                <b> / </b>
                {row.raceHorseKey.horse.dam.sire &&
                    <Link underline="hover" href={'/details/sire/' + row.raceHorseKey.horse.dam.sire.id}
                          target="_blank">{row.raceHorseKey.horse.dam.sire.name} </Link>}
            </span>
        )
    }, {
        title: 'Kilo',
        name: 'weight'
    }, {
        title: 'Jokey',
        name: 'jockey.name',
        getCellValue: row => (row.jockey.name)
    }, {
        title: 'St',
        name: 'startNo'
    }, {
        title: 'Son 6 Y.',
        name: 'lastSix',
        getCellValue: row =>
        {
            let last6 = row.lastSix;
            let greenFlag = false;
            let coloredNodeArray = [];
            for (let i = 0; i < last6.length; i++)
            {
                let currentChar = last6.charAt(i);
                let keyData = row.raceHorseKey.horse.id.toString() + '-' + i.toString();
                switch (currentChar)
                {
                    case 'K':
                        break;
                    case 'C':
                        greenFlag = true;
                        break;
                    default:
                        if (greenFlag)
                        {
                            coloredNodeArray.push(React.createElement('span', {
                                key: keyData,
                                style: {color: "#009900"}
                            }, currentChar));
                            greenFlag = false;
                        } else
                        {
                            coloredNodeArray.push(React.createElement('span', {
                                key: keyData,
                                style: {color: "#B4946E"}
                            }, currentChar));
                        }
                        break;
                }
            }
            return React.createElement('b', {key: row.raceHorseKey.horse.id}, coloredNodeArray);
        }
    }, {
        title: 'KGS',
        name: 'lastRun'
    }, {
        title: 's20',
        name: 's20'
    },
    {
        title: 'HP',
        name: 'handicap'
    }
];

const ResultColumns = [{
    title: 'S',
    name: 'result'
}, {
    title: 'At ismi',
    name: 'raceHorseKey.horse',
    getCellValue: row => (
        <span>
            <Link underline="hover" href={'/details/horse/' + row.raceHorseKey.horse.id}
                  target="_blank">{row.raceHorseKey.horse.name}({row.no}) </Link>
            <sup> {row.accessory}</sup>
        </span>
    )
}, {
    title: 'Yaş',
    name: 'age'
}, {
    title: 'Baba',
    name: 'raceHorseKey.sire',
    getCellValue: row => (
        <Link underline="hover" href={'/details/sire/' + row.raceHorseKey.horse.sire.id} target="_blank">
            {row.raceHorseKey.horse.sire.name} </Link>)
}, {
    title: 'Anne',
    name: 'raceHorseKey.dam',
    getCellValue: row => (
        <Link underline="hover" href={'/details/dam/' + row.raceHorseKey.horse.dam.id} target="_blank">
            {row.raceHorseKey.horse.dam.name} </Link>)
}, {
    title: 'Kilo',
    name: 'weight'
}, {
    title: 'Jokey',
    name: 'jockey.name',
    getCellValue: row => (row.jockey.name)
}, {
    title: 'St',
    name: 'startNo'
}, {
    title: 'Derece',
    name: 'degree',
    getCellValue: row => (row.degree ? moment(row.degree * 1000).format('mm.ss.SS') : 'Derecesiz')
}, {
    title: 'Ganyan',
    name: 'odds'
}
];

const EstimationColumns = [{
    title: 'No',
    name: 'no'
}, {
    title: 'At ismi',
    name: 'raceHorseKey.horse',
    getCellValue: row => (
        <span>
            <Link underline="hover" href={'/details/horse/' + row.raceHorseKey.horse.id}
                  target="_blank">{row.raceHorseKey.horse.name} </Link>
            <sup> {row.accessory}</sup>
        </span>
    )
}, {
    title: 'Kilo',
    name: 'weight'
}, {
    title: 'Jokey',
    name: 'jockey.name',
    getCellValue: row => (row.jockey.name + (row.estimation ? (row.estimation.predictedDegree.totalRace ? ( " (" +
        row.estimation.predictedDegree.totalJockeyRaceInDate.toString() + '/' +
        row.estimation.predictedDegree.totalJockeyRace.toString() + ")") : " (0/0)") : ""))
}, {
    title: 'KGS',
    name: 'lastRun'
}, {
    title: 'Aynı Mesafe',
    name: 'estimation.predictedDegree.averageDegreeOnSameDistance',
    getCellValue: row => row.estimation ? (row.estimation.predictedDegree.averageDegreeOnSameDistance ?
        moment(row.estimation.predictedDegree.averageDegreeOnSameDistance * 1000).format('mm.ss.SS') : 'Hesaplanamadı') : '-'
}, {
    title: 'Ortalama',
    name: 'estimation.predictedDegree.averageDegree',
    getCellValue: row => row.estimation ? (row.estimation.predictedDegree.averageDegree ?
        moment(row.estimation.predictedDegree.averageDegree * 1000).format('mm.ss.SS') : 'Hesaplanamadı') : '-'
}, {
    title: 'En Iyi',
    name: 'estimation.predictedDegree.bestDegree',
    getCellValue: row => row.estimation ? (row.estimation.predictedDegree.bestDegree ?
        moment(row.estimation.predictedDegree.bestDegree * 1000).format('mm.ss.SS') : 'Hesaplanamadı') : '-'
}, {
    title: 'HGS',
    name: 'estimation.predictedDegree.daysAfterLastCalculatedRace',
    getCellValue: row => (row.estimation ? row.estimation.predictedDegree.daysAfterLastCalculatedRace : '-')
}, {
    title: 'Yarış Sayısı',
    name: 'estimation.raceCount',
    getCellValue: row =>
    {
        return row.estimation ? (row.estimation.predictedDegree.totalRace ? (
            row.estimation.predictedDegree.totalCalculatedRaceAfterElimination.toString() + '/' +
            row.estimation.predictedDegree.totalCalculatedRace.toString() + '/' +
            row.estimation.predictedDegree.totalRaceInDate.toString() + '/' +
            row.estimation.predictedDegree.totalRace.toString()) : "0/0/0/0") : '-';
    }
}, {
    title: 'At P.',
    name: 'estimation.horseRating',
    getCellValue: row => row.estimation ? (row.estimation.horseRating.toFixed(2)) : '-'
}, {
    title: 'Orijin P.',
    name: 'estimation.originRating',
    getCellValue: row => row.estimation ? (row.estimation.originRating.toFixed(2)) : '-'
}
];

const ColumnWidths = [

    {columnName: 'no', width: 80},
    {columnName: 'age', width: 80},
    {columnName: 'raceHorseKey.horse', width: 220},
    {columnName: 'raceHorseKey.sire', width: 180},
    {columnName: 'raceHorseKey.dam', width: 230},
    {columnName: 'weight', width: 80},
    {columnName: 'jockey.name', width: 150},
    {columnName: 'startNo', width: 60},
    {columnName: 'lastSix', width: 100},
    {columnName: 'lastRun', width: 80},
    {columnName: 's20', width: 80},
    {columnName: 'handicap', width: 80},
    {columnName: 'result', width: 80},
    {columnName: 'degree', width: 120},
    {columnName: 'odds', width: 120},
    {columnName: 'estimation.predictedDegree.averageDegreeOnSameDistance', width: 120},
    {columnName: 'estimation.predictedDegree.averageDegree', width: 120},
    {columnName: 'estimation.predictedDegree.bestDegree', width: 120},
    {columnName: 'estimation.predictedDegree.daysAfterLastCalculatedRace', width: 80},
    {columnName: 'estimation.raceCount', width: 120},
    {columnName: 'estimation.horseRating', width: 100},
    {columnName: 'estimation.originRating', width: 100}
];


const CompareLinks = (a, b) =>
{
    const aStr = a.props.children[0].toString();
    const bStr = b.props.children[0].toString();
    return aStr.localeCompare(bStr);
};

const CompareNullable = (a, b) =>
{

    if (a == null)
    {
        return 1;
    }
    if (b == null)
    {
        return -1;
    }
    if (a === b)
    {
        return 0;
    }
    return a < b ? -1 : 1;
};

const CompareFloat = (a, b) =>
{
    if (a == null)
    {
        return -1;
    }
    if (b == null)
    {
        return 1;
    }
    if (a === b)
    {
        return 0;
    }

    return parseFloat(a) < parseFloat(b) ? 1 : -1;
};

const SortingColumnExtensions = [
    {columnName: 'raceHorseKey.horse', compare: CompareLinks},
    {columnName: 'raceHorseKey.sire', compare: CompareLinks},
    {columnName: 'raceHorseKey.dam', compare: CompareLinks},
    {columnName: 'odds', compare: CompareNullable},
    {columnName: 'result', compare: CompareNullable},
    {columnName: 'handicap', compare: CompareNullable},
    {columnName: 'lastRun', compare: CompareNullable},
    {columnName: 'lastSix', compare: CompareNullable},
    {columnName: 's20', compare: CompareNullable},
    {columnName: 'estimation.horseRating', compare: CompareFloat},
    {columnName: 'estimation.originRating', compare: CompareFloat},
    {columnName: 'estimation.predictedDegree.daysAfterLastCalculatedRace', compare: CompareNullable},
];


export {ProgramColumns, ResultColumns, EstimationColumns, ColumnWidths, SortingColumnExtensions};