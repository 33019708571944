import React, {useState, useEffect} from "react";
import Div from "@jumbo/shared/Div";
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Grid as DataGrid, Table, TableHeaderRow, TableColumnResizing} from '@devexpress/dx-react-grid-material-ui';
import {SortingState, IntegratedSorting} from '@devexpress/dx-react-grid';
import {ProgramColumns, ResultColumns, EstimationColumns, ColumnWidths, SortingColumnExtensions} from './tableConfig'
import Grid from '@mui/material/Grid';
import Estimator from "./Estimator"
import {API_URL} from "../../../utils/constants/apiUrl";
import {alpha, styled} from "@mui/material/styles";
import {Backdrop, CircularProgress} from "@mui/material";

const classes = {
    tableStriped: `race-tableStriped`,
};
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
    },
}));

const TableComponent = props => (
    <StyledTable
        {...props}
        className={classes.tableStriped}
    />
);

const Race = ({date, raceCourse, dirtState, race}) =>
{
    const [sorting, setSorting] = useState([{columnName: 'no', direction: 'asc'}]);
    const [columns, setColumns] = useState(ProgramColumns);
    const [columnWidths, setColumnWidths] = useState(ColumnWidths);
    const [view, setView] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [estimations, setEstimations] = React.useState(null);

    useEffect(() =>
    {
        setView(0);
        setEstimations(null);
        setColumns(ProgramColumns);
        setSorting([{columnName: 'no', direction: 'asc'}]);
    }, [race])

    const onViewChange = (event, newValue) =>
    {
        switch (newValue)
        {
            case 0:
                setColumns(ProgramColumns);
                setSorting([{columnName: 'no', direction: 'asc'}]);
                break;
            case 1:
                setColumns(ResultColumns);
                setSorting([{columnName: 'result', direction: 'asc'}]);
                break;
            case 2:
                setColumns(EstimationColumns);
                setSorting([{columnName: 'estimation.predictedDegree.averageDegree', direction: 'asc'}]);
                break;
            default:
                break
        }
        setView(newValue);
    };

    const onCalculate = (parameters) =>
    {
        setLoading(true);
        let raceId = race.id;
        let url = API_URL + "/calculate/race/" + raceId.toString();
        let myRequest = new Request(url,
            {
                method: 'POST',
                headers: new Headers({'content-type': 'application/json'}),
                body: JSON.stringify(parameters)
            });
        fetch(myRequest)
            .then(res =>
            {
                if (res.status === 200)
                {
                    return res.json();
                } else
                {
                    throw new Error('Hesaplama hatası');
                }
            })
            .then(res =>
            {
                setEstimations(res);
                setView(2);
                setColumns(EstimationColumns);
                setSorting([{columnName: 'estimation.predictedDegree.averageDegree', direction: 'asc'}]);
                setLoading(false);
            })
            .catch(err =>
            {
                setEstimations(null);
                setLoading(false);
                alert(err);
            });
    }

    if (estimations)
    {
        for (let h = 0; h < race.horses.length; h++)
        {
            race.horses[h].estimation = estimations[race.horses[h].raceHorseKey.horse.id];
        }
    }

    return (
        <Div sx={{flexGrow: 1}}>
            <h3 style={{
                fontWeight: "normal",
                color: "white",
                backgroundColor: race.track.id === 1 ? "#009900" : race.track.id === 2 ? "#996633" : "#D39B1E",
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "0px"
            }}>
                <span>{race.raceNo.toString() + '. Koşu:' + race.time}</span>
                <span style={{float: 'right'}}>
                                    {
                                        race.type.name + ', ' +
                                        race.group.name + ', ' +
                                        race.distance.toString() + ' ' +
                                        race.track.name + ' '
                                    }
                                </span>
            </h3>
            <Grid container spacing={2}>
                <Grid item xl={10} xs={12}>
                    <Tabs value={view} onChange={onViewChange}>
                        <Tab label="Program"/>
                        <Tab label="Sonuçlar"/>
                        {estimations
                            ? <Tab label="Hesaplamalar"/>
                            : <Tab label="Hesaplamalar" disabled/>
                        }
                    </Tabs>
                    <Paper style={{position: 'relative'}}>
                        <DataGrid
                            rows={race.horses}
                            columns={columns}
                        >
                            <SortingState
                                sorting={sorting}
                                onSortingChange={setSorting}
                            />
                            <IntegratedSorting
                                columnExtensions={SortingColumnExtensions}
                            />
                            <Table
                                tableComponent={TableComponent}
                            />
                            <TableColumnResizing
                                columnWidths={columnWidths}
                                onColumnWidthsChange={setColumnWidths}
                            />
                            <TableHeaderRow showSortingControls/>
                        </DataGrid>
                    </Paper>
                </Grid>
                <Grid item xl={2} xs={12}>
                    <Estimator date={date}
                               raceCourse={raceCourse}
                               dirtState={dirtState}
                               race={race}
                               loading={loading}
                               onCalculate={onCalculate}/>
                </Grid>
            </Grid>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Div>
    );
}

export default Race;